/* eslint-disable no-unused-expressions */
const mobileA11y = () => {
  const mainWrapper = document.getElementById('main-wrapper');
  const header = document.getElementsByTagName('th-header')[0];
  const main = document.getElementById('main');
  const footer = document.getElementsByTagName('th-footer')[0];

  const toggleAriaHiddenOnWrapper = (bool) => {
    const childrenArr = Array.from(mainWrapper.children);
    const childrenExceptHeader = childrenArr.filter(
      (c) => c.tagName.toLowerCase() !== 'th-header',
    );
    childrenExceptHeader.forEach((c) => c.setAttribute('aria-hidden', bool));
  };

  const toggleAriaHiddenOnHeader = (bool) => {
    header && header.setAttribute('aria-hidden', bool);
  };

  const toggleAriaHiddenMfe = (bool) => {
    // mfe layout does not have a #main-wrapper
    main && main.setAttribute('aria-hidden', bool);
    footer && footer.setAttribute('aria-hidden', bool);
  };

  const toggleAriaHidden = (bool) => {
    if (mainWrapper) {
      toggleAriaHiddenOnWrapper(bool);
    } else {
      toggleAriaHiddenMfe(bool);
    }
  };

  document.addEventListener('trailhead_mobile_menu_opened', () => {
    toggleAriaHidden(true);
  });

  document.addEventListener('trailhead_mobile_menu_closed', () => {
    toggleAriaHidden(false);
  });

  document.addEventListener('trailhead_details_modal_opened', () => {
    toggleAriaHidden(true);
    toggleAriaHiddenOnHeader(true);
  });

  document.addEventListener('trailhead_details_modal_closed', () => {
    toggleAriaHidden(false);
    toggleAriaHiddenOnHeader(false);
  });
};

document.addEventListener('DOMContentLoaded', mobileA11y);
